import React from 'react'
import { meetingState } from '@/states/request-meeting';
import {
  LocalVideo,
  RemoteVideo,
  useLocalVideo,
  useMeetingStatus,
  useRemoteVideoTileState,
  useToggleLocalMute,
  useMeetingManager
} from 'amazon-chime-sdk-component-library-react';
import { useRecoilState } from 'recoil';
import ControlMeeting from './ControlMeeting';
import NoRemoteVideoView from './NoRemoteVideoView';
import NotifySecond from './NotifySecond';
import SlideBurgerMenu from './SlideBurgerMenu';
import ModalEndMeeting from './ModalEndMeeting';
import NotifyUserLeft from './NotifyUserLeft';
import { getOs } from '@helpers/device';
import { OS } from '@helpers/types';
import { useMeeting } from '../../MeetingConfirm/hooks/useJoinMeeting/useMeeting';

interface IProps {
  isNotifyUserLeft: boolean;
  partnerTileId: number | null | undefined;
}

const Meeting = ({ isNotifyUserLeft, partnerTileId }: IProps) => {
  const { toggleVideo, isVideoEnabled } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const { tiles } = useRemoteVideoTileState();
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [profileRandom, setProfileRandom] = React.useState<number>(0);
  const [state_meeting] = useRecoilState(meetingState);
  const refGridContainer = React.useRef<HTMLDivElement>(null);
  const [isShowEndMeeting, setIsShowEndMeeting] = React.useState<boolean>(false);
  const { leaveMeeting } = useMeeting();

  React.useEffect(() => {
    if (meetingStatus === 1) {
      if (!isVideoEnabled) toggleVideo();
      //お見合い開始時にmuteを解除
      meetingManager?.meetingSession?.audioVideo.realtimeUnmuteLocalAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingStatus]);

  const onViewProfile = () => {
    setIsOpen(!isOpen);
  };
  React.useEffect(() => {
    if (!isOpen) {
      setProfileRandom(profileRandom + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    if (getOs() === OS.IOS) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = 'visible';
      }
    }
  }, []);

  const toggleChangeVideo = () => {
    toggleVideo();
  };

  const handleEndMeeting = async () => {
    await leaveMeeting();
    setIsShowEndMeeting(false);
    // jump to anthor page
    window.location.replace(state_meeting?.zwei_meeting_info?.url_redirect || '');
  };

  return (
    <div ref={refGridContainer} className="video-title-grid-container">
      <NotifySecond setIsShowEndMeeting={setIsShowEndMeeting} />
      {isNotifyUserLeft && <NotifyUserLeft />}
      <div className="video-container">
        <div className="d-flex flex-row">
          <div className="video-container-left">
            <RemoteVideo tileId={partnerTileId || 0} className="remote-video" />
          </div>
          <div className="video-container-right">
            <div className="video-remote-container">
              <div className="video-remote mb-3">
                {isVideoEnabled ? <LocalVideo nameplate="" /> : <NoRemoteVideoView text="非表示" />}
              </div>
              {tiles
                ?.filter((i) => i !== partnerTileId)
                .map((tileId, key) => {
                  return (
                    <div key={key} className="video-remote mb-3">
                      <RemoteVideo tileId={tileId} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <ControlMeeting
        isVideoEnabled={isVideoEnabled}
        muted={muted}
        isOpen={isOpen}
        onViewProfile={onViewProfile}
        toggleMute={toggleMute}
        toggleVideo={toggleChangeVideo}
        setIsShowEndMeeting={setIsShowEndMeeting}
      />
      <SlideBurgerMenu
        isOpen={isOpen}
        right={true}
        profileRandom={profileRandom}
        iframeSrc={state_meeting?.zwei_meeting_info?.detail_page_url}
      />
      <ModalEndMeeting show={isShowEndMeeting} handleClose={handleEndMeeting} />
    </div>
  )
}

export default Meeting