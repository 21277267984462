/** @format */

import '@modules/Meetings/styles/meeting-start.scss';
import React from 'react';
import Meeting from './components/Meeting';
import ReactLoading from 'react-loading';
import { useMeeting } from '../MeetingConfirm/hooks/useJoinMeeting/useMeeting';
import ProfilePage from '../Profiles';
import { useMeetingStart } from './hooks/useMeetingStart';

function MeetingStart() {
  const { loading } = useMeeting();
  const { readyOmiai, isNotifyUserLeft, partnerTileId } = useMeetingStart();

  return (
    <div id="meetingStartPages">
      <div className="meeting-container" style={{ "--vh": `${window.innerHeight * 0.01}px`, overflow: "hidden" } as React.CSSProperties}>
        {readyOmiai ? <Meeting isNotifyUserLeft={isNotifyUserLeft} partnerTileId={partnerTileId} /> : (
          <ProfilePage />
        )}
      </div>
      {loading && <ReactLoading className="loading-video-call" type="spokes" color="#009943" />}
    </div>
  );
}

export default MeetingStart;
