import React from 'react';
import { RequestIdInfo, Time } from '@helpers/types';
import { isEmptyObject } from '@helpers/object';
import { getTimeStart, objectTimeAddHalfHour, diffSecondsFromNow, convertSecondsToMinutesSeconds } from '@helpers/time';
import { useSearchParams } from 'react-router-dom';

export function useCountUpNumber() {
  const TIME_START_NOTIFY = 10;
  const TIME_END_NOTIFY = 0;
  const [timeLeft, setTimeLeft] = React.useState<{ minutes?: number; seconds?: number }>({});
  const [timeEndObject, setTimeEndObject] = React.useState<Time | {}>({});
  const [isOpenNotify, setOpenNotify] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const request_id = searchParams.get('request_id');

  React.useEffect(() => {
    if (request_id) {
      const requestIdInfo: RequestIdInfo = getTimeStart(request_id);
      setTimeEndObject(objectTimeAddHalfHour(requestIdInfo.timeStart));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (!isEmptyObject(timeEndObject))
      interval = setInterval(() => {
        // if (minute < TIME_MAX) {
        //   setSecond((prevSecond) => prevSecond++);
        // }
        const { minutes, seconds } = convertSecondsToMinutesSeconds(diffSecondsFromNow(timeEndObject as Time));
        setTimeLeft({ minutes, seconds });
      }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timeEndObject]);

  React.useEffect(() => {
    if (typeof timeLeft.minutes !== 'undefined' && typeof timeLeft.seconds !== 'undefined') {
      if (timeLeft.minutes <= TIME_START_NOTIFY) {
        setOpenNotify(true);
      }
      if (
        (timeLeft.minutes === TIME_END_NOTIFY && timeLeft.seconds === TIME_END_NOTIFY) ||
        timeLeft.minutes < TIME_END_NOTIFY ||
        timeLeft.seconds < TIME_END_NOTIFY
      ) {
        setOpenNotify(false);
      }
    }
  }, [timeLeft]);

  return { timeLeft, isOpenNotify, TIME_END_NOTIFY };
}
