import React from 'react';
import { meetingState } from '@/states/request-meeting';
import { useRecoilState } from 'recoil';
import { useRosterState, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useGetStatusAttendee } from '../../MeetingConfirm/hooks/useGetStatusAttendee/useGetStatusAttendee';

const INTERVAL_TIME = 30000;

export function useMeetingStart() {
  const meetingManager = useMeetingManager();
  const [state_meeting] = useRecoilState(meetingState);
  const { getVideoStatusAttendee } = useGetStatusAttendee();
  const [isNotifyUserLeft, setIsNotifyUserLeft] = React.useState<boolean>(false);
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const [isTwoUserJoined, setIsTwoUserJoined] = React.useState<boolean>(false);
  const [readyOmiai, setReadyOmiai] = React.useState<boolean>(false);
  const partnerTileId = React.useMemo(() => {
    // お相手のTitleId
    let data;
    for (var i = 0; i < attendees?.length; i++) {
      if (attendees[i]?.chimeAttendeeId !== state_meeting?.attendeeInfo.AttendeeId) {
        if (
          attendees[i]?.externalUserId === state_meeting?.zwei_meeting_info?.member_id ||
          attendees[i]?.externalUserId === state_meeting?.zwei_meeting_info?.partner_id
        ) {
          data = getVideoStatusAttendee(attendees[i]?.chimeAttendeeId);
          break;
        }
      }
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendees]);

  // React.useEffect(() => {
  //   console.log('Roster changed', roster);
  // }, [roster]);

  React.useEffect(() => {
    // console.log('attendees changed', attendees);
    if (checkStartOmiai()) setIsTwoUserJoined(true);
    checkOneUserLeft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendees]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!state_meeting?.zwei_meeting_info?.matchmaking_at || !isTwoUserJoined) return;
      const start_date = new Date(state_meeting?.zwei_meeting_info?.matchmaking_at);
      const now = new Date();
      if (now >= start_date) setReadyOmiai(true);
    }, INTERVAL_TIME);

    return () => {
      clearInterval(interval);
    };
  }, [state_meeting, isTwoUserJoined]);

  const checkStartOmiai = React.useCallback(() => {
    if (!state_meeting?.zwei_meeting_info) return false;
    //atendeeのビデオが準備できているか
    let remote_attendee_ids = meetingManager?.audioVideo
      ?.getRemoteVideoSources()
      .map((i) => i?.attendee?.externalUserId);
    let attendee_ids = remote_attendee_ids?.concat(state_meeting?.zwei_meeting_info?.my_id);
    if (
      !(
        attendee_ids?.includes(state_meeting?.zwei_meeting_info?.member_id) &&
        attendee_ids?.includes(state_meeting?.zwei_meeting_info?.partner_id)
      )
    ) {
      return false;
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_meeting]);

  const checkOneUserLeft = () => {
    const externalUserIds = attendees?.map((attendee) => attendee.externalUserId);
    // console.log('externalUserIds:', externalUserIds);
    if (
      !(
        externalUserIds?.includes(state_meeting?.zwei_meeting_info?.member_id) &&
        externalUserIds?.includes(state_meeting?.zwei_meeting_info?.partner_id)
      )
    ) {
      setIsNotifyUserLeft(true);
    } else {
      setIsNotifyUserLeft(false);
    }
  };

  return {
    readyOmiai,
    isNotifyUserLeft,
    partnerTileId,
  };
}
